var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content", staticStyle: { padding: "20px 40px" } },
    [
      _c(
        "header",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.switchView,
              expression: "!switchView",
            },
          ],
          staticClass: "header",
        },
        [_c("Search", { on: { search: _vm.search } }), _c("div")],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          !_vm.switchView
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.switchView = true
                    },
                  },
                },
                [_vm._v("切换监控视图")]
              )
            : _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.switchView = false
                    },
                  },
                },
                [_vm._v("切换列表视图")]
              ),
        ],
        1
      ),
      _c("ListView", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.switchView,
            expression: "!switchView",
          },
        ],
        ref: "listView",
        on: { toMonitoringView: _vm.toMonitoringView },
      }),
      _c("MonitoringView", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.switchView,
            expression: "switchView",
          },
        ],
        attrs: { value: _vm.monitoringValue, searchParams: _vm.searchParams },
      }),
      _c("AddThirdVideo", {
        model: {
          value: _vm.addVideoDialog,
          callback: function ($$v) {
            _vm.addVideoDialog = $$v
          },
          expression: "addVideoDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }