<template>
  <div class="monitoringView">
    <div class="menu">
      <a-input-search
        placeholder="请输入设备名称"
        style="width: 200px"
        @search="onSearch"
      />
      <div class="list">
        <a-tree :tree-data="treeData" @select="selectVideo"></a-tree>
      </div>
    </div>
    <VideoPage ref="videoPage" />
  </div>
</template>

<script>
import VideoPage from "./video/Index.vue";
import { getSysCameraInfoGroupTree } from "@/api/viewMonitoring.js";
export default {
  components: {
    VideoPage,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      treeParams: {},
      treeData: [],
    };
  },
  methods: {
    //获取树形列表数据
    getDataTree() {
      getSysCameraInfoGroupTree(this.treeParams).then((res) => {
        console.log("树形结构", res);
        this.treeData = Object.keys(res.data).map((key, index) => {
          return {
            title: key,
            key: index + 1,
            kind: 0,
            children: res.data[key].map((item) => {
              return {
                ...item,
                title: item.name,
                key: item.id,
                kind: 1,
              };
            }),
          };
        });
        console.log(this.treeData);
      });
    },
    //选择播放视频
    selectVideo(keys, e) {
      const data = e.selectedNodes[0].data.props
      console.log(data);
      if (data.kind === 0) return
      this.$refs["videoPage"].changeVideo(data)
    },
    //根据设备名称搜索设备
    onSearch(val) {
      console.log("搜索框数据", val);
      this.treeParams.keyword = val
      this.getDataTree();
    },
  },
  watch: {
    value: {
      handler(val) {
        console.log("查看监控", val);
        this.$refs["videoPage"] && this.$refs["videoPage"].changeVideo(val)
      },
      immediate: true,
    },
  },
  created() {
    this.getDataTree();
  },
};
</script>

<style scoped lang="scss">
.monitoringView {
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  .menu {
    margin-right: 15px;
    width: 200px;
    .list {
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 20px;
      border: 1px solid #ccc;
      overflow-y: auto;
    }
  }
}
</style>
