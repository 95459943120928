var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "videoCard" },
    [
      !_vm.hasUrl
        ? _c("div", { staticClass: "default" }, [_vm._v("暂无视频")])
        : _c("video-player", {
            ref: "videoPlayer",
            attrs: { playsinline: true, options: _vm.playerOptions },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }