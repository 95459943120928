<template>
  <div style="padding: 20px 40px" class="content">
    <header class="header" v-show="!switchView">
      <Search @search="search"/>
      <div>
        <!-- <a-button type="primary" @click="addVideoDialog = true"
          >添加第三方视频</a-button
        >
        <a-button type="primary" style="margin-left:15px"
          >添加视频设备</a-button
        > -->
      </div>
    </header>
    <div style="margin-bottom: 20px">
      <a-button v-if="!switchView" type="primary" @click="switchView = true"
        >切换监控视图</a-button
      >
      <a-button v-else type="primary" @click="switchView = false"
        >切换列表视图</a-button
      >
    </div>
    <ListView
      v-show="!switchView"
      @toMonitoringView="toMonitoringView"
      ref="listView"
    />
    <MonitoringView
      v-show="switchView"
      :value="monitoringValue"
      :searchParams="searchParams"
    />
    <AddThirdVideo v-model="addVideoDialog" />
  </div>
</template>

<script>
import Search from "./components/Search";
import AddThirdVideo from "./components/AddThirdVideo.vue";
import ListView from "./components/ListView.vue";
import MonitoringView from "./components/MonitoringView.vue";
export default {
  components: {
    Search,
    AddThirdVideo,
    ListView,
    MonitoringView,
  },
  data() {
    return {
      addVideoDialog: false, //是否打开添加视频弹窗
      switchView: false, //切换视图
      monitoringValue: {}, //监控视图传入数据
      searchParams: {},
    };
  },
  methods: {
    //点击查看监控切换到监控视图
    toMonitoringView(data) {
      this.switchView = true;
      this.monitoringValue = data;
    },
    //查询
    search(data) {
      this.$refs['listView'].search(data)
    },
  },
  watch: {
    switchView(bol) {
      if (!bol) {
        this.$refs['listView'].getDataList()
      }
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.content{
  height: calc(100% - 100px);
}
</style>
