var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitoringView" },
    [
      _c(
        "div",
        { staticClass: "menu" },
        [
          _c("a-input-search", {
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入设备名称" },
            on: { search: _vm.onSearch },
          }),
          _c(
            "div",
            { staticClass: "list" },
            [
              _c("a-tree", {
                attrs: { "tree-data": _vm.treeData },
                on: { select: _vm.selectVideo },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("VideoPage", { ref: "videoPage" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }