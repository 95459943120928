<template>
  <div class="lisiView">
    <a-table
      class="listTable"
      :columns="listColumn"
      :data-source="listData"
      row-key="id"
      :pagination="false"
    >
      <template slot="status" slot-scope="text, record">
        <span>{{ record.status === 0 ? "离线" : "在线" }}</span>
      </template>
      <template slot="type" slot-scope="text, record">
        <span>{{ record.type === 0 ? "IPcamera" : "NVR" }}</span>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a href="javascript:void(0);" @click="lookMonitoring(record)"
          >查看监控</a
        >
        <!-- <a href="javascript:void(0);" style="margin: 0 10px">配置</a> -->
        <a
          href="javascript:void(0);"
          style="margin-left: 10px"
          @click="onDeleteDevice(record)"
          >删除</a
        >
      </template>
    </a-table>
    <Pagination
      :current-page="listParams.page"
      :count="total"
      @handleChange="changePage"
      @handleChangeSize="changeSize"
    />
  </div>
</template>

<script>
import { listColumn } from "../data";
import Pagination from "@/components/Pagination";
import {
  getSysCameraInfoPage,
  removeSysCameraInfo,
} from "@/api/viewMonitoring.js";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      listColumn,
      listData: [], //列表数据
      total: 0, //总条数
      listParams: {
        page: 1, //当前页码
        pageSize: 10, //每页条数
      },
      selectedRowKeys: [],
    };
  },
  methods: {
    //获取列表数据
    getDataList() {
      getSysCameraInfoPage(this.listParams).then((res) => {
        console.log("datalist", res);
        this.listData = res.data.records;
        this.total = res.total;
      });
    },
    //查看监控
    lookMonitoring(data) {
      this.$emit("toMonitoringView", data);
    },
    //删除设备
    onDeleteDevice(data) {
      console.log("delete", data);
      this.$confirm({
        title: "您确认删除设备吗？",
        okText: "确定",
        centered: true,
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          removeSysCameraInfo(data.id).then((res) => {
            if (res.success) {
              this.$message.success("删除成功！");
            } else {
              this.$message.error(res.errorMsg);
            }
          });
        },
      });
    },
    // 选择分页
    changePage(page) {
      this.listParams.page = page;
    },
    // 修改每页数据条数
    changeSize(size) {
      this.listParams.pageSize = size;
    },
    //选择列表项
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    //根据条件筛选列表
    search(data) {
      this.listParams = Object.assign(this.listParams, data);
      this.getDataList();
    },
  },
  created() {
    this.getDataList();
  },
};
</script>

<style scoped lang="scss">
.lisiView {
  ::v-deep .ant-pagination {
    text-align: right;
  }
  ::v-deep .ant-pagination-total-text {
    float: left;
  }
  .listTable {
    margin-bottom: 15px;
  }
}
</style>
