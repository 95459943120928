var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c(
        "a-select",
        {
          staticStyle: { width: "120px", "margin-right": "15px" },
          attrs: { placeholder: "设备类型", allowClear: true },
          model: {
            value: _vm.searchForm.type,
            callback: function ($$v) {
              _vm.$set(_vm.searchForm, "type", $$v)
            },
            expression: "searchForm.type",
          },
        },
        [
          _c("a-select-option", { attrs: { value: "0" } }, [
            _vm._v(" IPcamera "),
          ]),
          _c("a-select-option", { attrs: { value: "1" } }, [_vm._v(" NVR ")]),
        ],
        1
      ),
      _c(
        "a-select",
        {
          staticStyle: { width: "120px" },
          attrs: { placeholder: "设备状态", allowClear: true },
          model: {
            value: _vm.searchForm.status,
            callback: function ($$v) {
              _vm.$set(_vm.searchForm, "status", $$v)
            },
            expression: "searchForm.status",
          },
        },
        [
          _c("a-select-option", { attrs: { value: "0" } }, [_vm._v(" 离线 ")]),
          _c("a-select-option", { attrs: { value: "1" } }, [_vm._v(" 在线 ")]),
        ],
        1
      ),
      _c("a-input", {
        staticStyle: { width: "300px", margin: "0 15px" },
        attrs: { placeholder: "请输入设备名称/id/分组名称" },
        model: {
          value: _vm.searchForm.keyword,
          callback: function ($$v) {
            _vm.$set(_vm.searchForm, "keyword", $$v)
          },
          expression: "searchForm.keyword",
        },
      }),
      _c(
        "a-button",
        { attrs: { type: "primary" }, on: { click: _vm.dealSearch } },
        [_vm._v("查询")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }