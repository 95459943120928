<template>
  <a-modal
    :width="800"
    :visible="visible"
    title="添加第三方视频"
    ok-text="确认"
    cancel-text="取消"
    @ok="addSubmit"
    @cancel="visible = false"
  ></a-modal>
</template>

<script>
export default {
  model: {
    prop: "addVideoDialog",
    event: "close",
  },
  props: {
    addVideoDialog: Boolean,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    addSubmit() {
      console.log("提交添加");
    },
  },
  watch: {
    addVideoDialog(newVal) {
      this.visible = newVal;
    },
    visible(val) {
      if (!val) {
        this.$emit("close", false);
      }
    },
  },
};
</script>

<style scoped></style>
