<template>
  <div class="videoCard">
    <div class="default" v-if="!hasUrl">暂无视频</div>
    <video-player
      v-else
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
    ></video-player>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
// import videojs from "video.js";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
// import "videojs-flash";
// import SWF_URL from "videojs-swf/dist/video-js.swf";
// videojs.options.flash.swf = SWF_URL; // 设置flash路径，Video.js会在不支持html5的浏览中使用flash播放视频文件

export default {
  components: {
    videoPlayer,
  },
  props: {
    itemData: {
      type: Object,
      default: () => ({
        key: 0,
        pullFlowUrl: "",
        isSelect: false,
      }),
    },
  },
  data() {
    return {
      hasUrl: false,
      playerOptions: {
        live: true,
        autoplay: true, // 如果true，浏览器准备好时开始播放
        muted: false, // 默认情况下将会消除任何音频
        loop: false, // 是否视频一结束就重新开始
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          currentTimeDisplay: false, // 当前时间
          volumeControl: false, // 声音控制键
          playToggle: false, // 暂停和播放键
          progressControl: false, // 进度条
          fullscreenToggle: true, // 全屏按钮
        },
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        // techOrder: ['flash'], // 兼容顺序
        // flash: {
        //   hls: {
        //     withCredentials: false
        //   },
        //   swf: SWF_URL
        // },
        sources: [
          {
            type: "application/x-mpegURL",
            src: "", // 视频地址-改变它的值播放的视频会改变
          },
        ],
      },
    };
  },
  watch: {
    itemData: {
      handler(obj) {
        console.log('地址更新', obj)
        if (!obj.pullFlowUrl) return;
        this.playerOptions.sources[0].src = obj.pullFlowUrl;
        this.hasUrl = true
      },
      deep: true
    },
  },
};
</script>

<style scoped lang="scss">
.video {
  width: 100%;
  height: 100%;
}
.default {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  padding-top: 20px;
}
</style>
