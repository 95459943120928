import request from "@/api/server/index"

/**
 * 分页查询
 * method: post
 * url: /sysCameraInfo/page
 * param page           number  当前页码，分页查询时传参数，列表查询不需要传 ,示例值(1)
 * param pageSize       number  每页记录数，分页查询时传参数，列表查询不需要传 ,示例值(10)
 * param name?          string  摄像头名称
 * param groupName?     string  设备分组名称
 * param code?          string  摄像头编码
*/
export function getSysCameraInfoPage(data) {
    return request({
        url: "/manager/cameraInfo/page",
        method: 'post',
        data
    })
}

/**
 * 根据ID逻辑删除
 * method: post
 * url: /sysCameraInfo/remove/{id}
 * param id  number
*/
export function removeSysCameraInfo(id) {
    return request({
        url: "/manager/cameraInfo/page/" + id,
        method: 'post'
    })
}

/**
 * 根据ID查询
 * method: post
 * url: /sysCameraInfo/get/{id}
 * param id  number
*/
export function getSysCameraInfoById(id) {
    return request({
        url: "/manager/cameraInfo/get/" + id,
        method: 'post'
    })
}

/**
 * 根据多个ID批量逻辑删除
 * method: post
 * url: /sysCameraInfo/batchRemoveById
 * param id  number[]
*/
export function batchRemoveById(data) {
    return request({
        url: "/manager/cameraInfo/batchRemoveById",
        method: 'post',
        data
    })
}

/**
 * 更新最近拉流时间
 * method: post
 * url: /sysCameraInfo/lastPull/{cameraId}
 * param cameraId  number
*/
export function getLastPullById(cameraId) {
    return request({
        url: "/manager/cameraInfo/lastPull/" + cameraId,
        method: 'get'
    })
}

/**
 * 获取监控视图侧边栏树
 * method: post
 * url: /sysCameraInfo/groupTree
 * param page?          number  当前页码，分页查询时传参数，列表查询不需要传 ,示例值(1)
 * param pageSize?      number  每页记录数，分页查询时传参数，列表查询不需要传 ,示例值(10)
 * param name?          string  摄像头名称
 * param groupName?     string  设备分组名称
 * param code?          string  摄像头编码
*/
export function getSysCameraInfoGroupTree(data) {
    return request({
        url: "/manager/cameraInfo/groupTree",
        method: 'post',
        data
    })
}
