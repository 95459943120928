var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lisiView" },
    [
      _c("a-table", {
        staticClass: "listTable",
        attrs: {
          columns: _vm.listColumn,
          "data-source": _vm.listData,
          "row-key": "id",
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(_vm._s(record.status === 0 ? "离线" : "在线")),
                ]),
              ]
            },
          },
          {
            key: "type",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(_vm._s(record.type === 0 ? "IPcamera" : "NVR")),
                ]),
              ]
            },
          },
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.lookMonitoring(record)
                      },
                    },
                  },
                  [_vm._v("查看监控")]
                ),
                _c(
                  "a",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onDeleteDevice(record)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("Pagination", {
        attrs: { "current-page": _vm.listParams.page, count: _vm.total },
        on: { handleChange: _vm.changePage, handleChangeSize: _vm.changeSize },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }