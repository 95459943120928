export const listColumn = [
    {
        title: '设备id(DeviceID)',
        dataIndex: 'id',
        key: '',
        align: 'center',
    },
    {
        title: '设备名称',
        dataIndex: 'name',
        key: '',
        align: 'center'
    },
    {
        title: '设备类型',
        dataIndex: 'type',
        key: '',
        align: 'center',
        scopedSlots: { customRender: 'type' }
    },
    {
        title: '设备状态',
        dataIndex: 'status',
        key: '',
        align: 'center',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: '通道数',
        dataIndex: 'aisleCount',
        key: '',
        align: 'center'
    },
    {
        title: '分组名称',
        dataIndex: 'groupName',
        key: '',
        align: 'center'
    },
    {
        title: '添加时间',
        dataIndex: 'createTime',
        key: '',
        align: 'center'
    },
    {
        title: '最近拉流时间',
        dataIndex: 'lastPullFlowTime',
        key: '',
        align: 'center'
    },
    {
        title: '操作',
        dataIndex: 'handle',
        key: '',
        scopedSlots: { customRender: 'operation' },
        align: 'center'
    }
]
