var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-modal", {
    attrs: {
      width: 800,
      visible: _vm.visible,
      title: "添加第三方视频",
      "ok-text": "确认",
      "cancel-text": "取消",
    },
    on: {
      ok: _vm.addSubmit,
      cancel: function ($event) {
        _vm.visible = false
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }