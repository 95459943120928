var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "viewWrap",
      class: ["view-wrap", _vm.isFullScreen ? "fullScreenView" : ""],
    },
    [
      _c(
        "div",
        { staticClass: "video-list" },
        _vm._l(_vm.slotKeys, function (item) {
          return _c(
            "div",
            {
              key: item.key,
              staticClass: "video",
              style:
                "width: " +
                100 / _vm.activeKey +
                "%;height: " +
                100 / _vm.activeKey +
                "%",
            },
            [
              _c("div", {
                staticClass: "modal",
                style:
                  "border: " +
                  (item && item.isSelect ? "3px solid rgb(68, 142, 247)" : ""),
                on: {
                  click: function ($event) {
                    return _vm.selectSlot(item)
                  },
                },
              }),
              _c("Video", { attrs: { itemData: item } }),
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "controls" }, [
        _c(
          "div",
          { staticClass: "pagesIcon", on: { click: _vm.changePageNum } },
          [
            _c("i", {
              class: [
                "iconfont icon-checkbox-full",
                _vm.activeKey === 1 ? "active" : "",
              ],
              attrs: { id: "1" },
            }),
            _c("i", {
              class: [
                "iconfont icon-four-squares-full",
                _vm.activeKey === 2 ? "active" : "",
              ],
              attrs: { id: "2" },
            }),
            _c("i", {
              class: [
                "iconfont icon-xiaosuolvetu",
                _vm.activeKey === 3 ? "active" : "",
              ],
              attrs: { id: "3" },
            }),
            _c("i", {
              class: [
                "iconfont icon-16gongge",
                _vm.activeKey === 4 ? "active" : "",
              ],
              attrs: { id: "4" },
            }),
          ]
        ),
        _c("i", {
          class: [
            "iconfont",
            _vm.isFullScreen ? "icon-suoxiao" : "icon-fangda",
          ],
          on: { click: _vm.changeFullScreen },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }