<template>
  <div style="display: flex;">
    <a-select
      style="width:120px;margin-right:15px"
      v-model="searchForm.type"
      placeholder="设备类型"
      :allowClear="true"
    >
      <a-select-option value="0">
        IPcamera
      </a-select-option>
      <a-select-option value="1">
        NVR
      </a-select-option>
    </a-select>
    <a-select
      style="width:120px"
      placeholder="设备状态"
      :allowClear="true"
      v-model="searchForm.status"
    >
      <a-select-option value="0">
        离线
      </a-select-option>
      <a-select-option value="1">
        在线
      </a-select-option>
    </a-select>
    <a-input
      v-model="searchForm.keyword"
      placeholder="请输入设备名称/id/分组名称"
      style="width:300px;margin: 0 15px"
    ></a-input>
    <a-button type="primary" @click="dealSearch">查询</a-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        keyword: "",
        status: undefined,
        type: undefined,
      },
    };
  },
  methods: {
    //查询
    dealSearch() {
      this.$emit('search', this.searchForm)
    },
  },
};
</script>

<style scoped></style>
